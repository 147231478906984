<template>
     <a-modal
        title="编辑"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="$emit('close')"
        @ok="handleSubmit"
        destroyOnClose
        :width="642"
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
        v-if="data"
    >
        <a-form-item label="分类">
             <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择分类' }] },]" >
                    <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
        </a-form-item>
        <a-form-item label="所含科目">
           <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择题库' }] },]" >
                <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                    {{item.name}}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="考试时间">
            <a-date-picker  v-decorator="[ 'username',{ initialValue: momentDay(text), rules: [{ required: true, message: '请选择考试时间' }] },]" @change="onChange(1)" />
        </a-form-item>
        <a-form-item label="有效时间">
            <a-date-picker :defaultValue="momentDay(text)" @change="onChange(2)"/>
        </a-form-item>
        <a-form-item label="原价（元）" >
            <a-input v-decorator="[ 'password', {rules: [{required: true, message: '请输入原价'}]}]" />
        </a-form-item>
        <a-form-item label="现价（元）" >
            <a-input v-decorator="[ 'password', {rules: [{required: true, message: '请输入现价'}]}]" />
        </a-form-item>
        <a-form-item label="学习次数" >
            <a-input v-decorator="[ 'password', {rules: [{required: true, message: '请输入学习次数'}]}]" />
        </a-form-item>
        <a-form-item label="学习基数" v-if="!data">
            <a-input v-decorator="[ 'surepassword', {rules: [{required: true, message: '请输入学习基数'}]}]" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { momentDay, formatDay } from '@/libs/moment'
export default {
    name: 'editClassSet',
    props: ['visible', 'subjectList', 'data'],
     beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
            },
            confirmLoading: false
        }
    },
    methods:{
        momentDay,
        handleSubmit(){
            this.form.validateFields((err, values) => {
                if(err) return;
            })
        },
        onChange(type){

        }
    }
}
</script>